/* Updated CSS for dark-themed form fields */

.contact-form-container {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    margin: auto;
    padding: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  input, textarea {
    font-family: Montserrat;
    background-color: #000; /* Black background */
    color: #ffcc00; /* Yellow text color */
    border: 1px solid #ffcc00; /* Yellow border */
    padding: 10px;
    border-radius: 15px; /* Rounded corners */
    font-size: min(2vw,40px);
    width: min(40vw,850px);
    font-weight: 700;
    outline: none;
  }

  textarea {
    height: 400px; /* Adjust the height to your preference */
    resize: vertical; /* Allows the user to only resize vertically */
  }

  input::placeholder,
  textarea::placeholder {
    color: #ffcc00; /* Yellow placeholder text */
    opacity: 0.7; /* Slightly faded placeholder */
  }
  
  form button {
    background-color: #ffcc00; /* Yellow button */
    color: #000; /* Black text on button */
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background-color: #e6b800; /* Slightly darker yellow on hover */
  }
  