.card {
    background-color: #0BB1F9; /* Blue background */
    border-radius: 12px; /* Rounded corners */
    padding: 10% 10% 15% 10%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: space-between; /* Space out the image and text */
    text-align: center;
    width: min(150px, 10vw); /* Width of the card */
    /*height: 175px;  Height of the card */
    color: rgb(0, 0, 0); /* Text color */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    transition: filter 0.5s ease, background-color 0.5s ease, transform 0.2s ease;
    z-index: 0;
    gap: 1em;
}

.card:hover {
    /* Add any hover styles you like here */
    transform: scale(1.1);
}

.card img {
     /* Image size */
    height: min(6vw, 100px);
}

.card h4 {
    margin: 0;
    font-size: min(1.3vw, 20px);
    font-weight: 600;
}

.blurred {
    filter: blur(1px);
    background-color: yellow;
    transition: filter 0.5s ease;
    transition: background-color 0.5s ease;
}
  
.card.hovered {
    transform: scale(1.1);
}