
@font-face {
  font-family: 'Montserrat'; /* Choose a name for your font */
  src:url('./Fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype'); /* Fallback */
  font-weight: normal; /* Set font-weight if applicable */
  font-style: normal; /* Set font-style if applicable */
}

@font-face {
  font-family: 'Montserrat'; /* Choose a name for your font */
  src:url('./Fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype'); /* Fallback */
  font-weight: 600; /* Set font-weight if applicable */
  font-style: normal; /* Set font-style if applicable */
}

@font-face {
  font-family: 'Montserrat'; /* Choose a name for your font */
  src:url('./Fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype'); /* Fallback */
  font-weight: 700; /* Set font-weight if applicable */
  font-style: normal; /* Set font-style if applicable */
}

@font-face {
  font-family: 'Impact'; /* Choose a name for your font */
  src:url('./Fonts/impact/impact.ttf') format('truetype'); /* Fallback */
  font-weight: normal; /* Set font-weight if applicable */
  font-style: normal; /* Set font-style if applicable */
}


/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.App {
  text-align: center;
  background-color: yellow;
}

.App-header {
  top: 0;
  position: sticky;
  background-color: yellow;
  border-bottom: 3px solid black;
  font-size: 25px;
  font-family: Montserrat;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  gap: 20px;
  overflow: hidden;
  z-index: 10;
}

.App-header .ButtonsContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.App-header a {
  color: rgb(0, 0, 0); /* Default color */
  text-decoration: none; /* Remove underline */
  padding: 10px; /* Add padding for clickable area */
  height: 50px;
  align-items: center;
  display: flex;
}

.App-header a.active {
  background-color: black;
  color: yellow; /* Highlight color for the active link */
}

.Footer a {
  color: yellow; /* Default color */
  text-decoration: none; /* Remove underline */
  padding: 10px; /* Add padding for clickable area */
}

.ButtonsFooter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.App-link {
  color: #61dafb;
}


.Hero a {
  font-family: Montserrat;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  text-decoration: underline;
  padding: 0vw;
  font-size: min(1.5vw, 25px);
}

.Footer a {
  font-family: Montserrat;
  text-decoration: none;
  color: inherit;
  font-size: 25px;
  font-weight: bold;
  padding: 20px;
}

.CaraTeriLink a {
  display: flex;
  flex-direction: row;
  font-family: Montserrat;
  text-decoration: underline;
  color: inherit;
}

.CaraTeriLink a:hover{
  transform: scale(1.1);
}

a:hover{
  transform: scale(1.2);
}

.Hero{
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: min(3vw,100px);
  margin: 3% 4% 0vw 4%;
}

.HeroImageContainer{
  position: relative;
}

.Hero .HeroImageContainer img{
  display: block;
  padding-top: 50px;
  width: min(1000px, 52vw);
}

.Hero h2{
  font-family: Impact;
  font-weight: 400;
  font-size: min(170px, 8vw);
  text-align: left;
  margin: 0;
  line-height: min(7.5vw,1em);
}

.Hero a:hover{
  transform: scale(1);
}

#SeeMore{
  padding: 1vw;
}

.Hero button{
  position: absolute;
  padding: 0;
}

/* For the button hover effect */
.Hero button:hover {
  background: none; /* No background on hover */
}

/* Change fill color of the path and stroke color of the circle on hover */
.Hero button:hover .icon-path {
  background: none;
  fill: #61dafb; /* Change fill color of the path */
}
.Hero button:hover .icon-circle {
  fill: #000000; /* Change fill color of the path */
}

h2{
  font-family: Impact;
  font-weight: 400;
  font-size: 60px;
  text-align: left;
  margin: 0;
}


h3{
  font-size: min(3vw,40px);
  text-align: left;
  margin: 0;
}

.HeroTitle{
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.FeaturedSkills {
  padding: min(0.5vw,20px);
  text-align: center;
}

.FeaturedSkillCards{
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, auto);
  gap: min(50px, 4vw);
  margin: min(1vw,20px) 0 min(1vw,20px) 0;
}

.Hero .FeaturedSkillCards .card{
  width: min(150px, 10vw); /* Width of the card */
}

.Hero .FeaturedSkillCards .card img {
  /* Image size */
 height: min(5vw, 100px);
}

.Hero .FeaturedSkillCards .card h4 {
 margin: 0;
 font-size: min(1.4vw, 20px);
 font-weight: 600;
}

.Hero .HeroContainer .HeroImageContainer button a{
  padding: 0;
}


.separator{
  border-top: 3px solid black; 
  margin: min(1vw, 20px) 0;}

.VerticalSeparator{
  border-left: 3px solid black; 
  margin: 0 0 0 20px;
  height: 600px;
}

#aka {
  font-size: min(1.5vw,30px);
  text-align: right;
  margin: 0;
}

.About{
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.About p{
  font-size: min(3vw,22px);
}

.AboutTitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.EyeTrackingImage{
  height: auto;
  width: min(45vw,750px);
}

#Who1{
  text-align: center;
  font-size: min(80px, 8vw);
}

#Who{
  font-size: 25px;
}

#Who2{
  text-align: center;
  font-size: min(4vw, 40px);
}

#RotatingTitle{
  padding-top: 80px;
  padding-bottom: 40px;
  font-size: 60px;
}

.AboutFirst{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding: 5%;
}

.AboutText{
  padding-bottom: 80px;
  width: min(60vw,750px);
}

.AboutText h3{
  overflow: hidden;
}

.AboutText > * {
  /*margin-bottom: 40px; /* Adjust this value as needed */
}

.AboutPicText{
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: center;
  margin: 50px 0 50px 0;
  gap: 20px;
}


.Best{
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  margin-bottom: 200px;
  width: min(1500px, 80vw);
  align-self: center;
}

.SkillCards{
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(6, auto);
  gap: 50px;
  margin: 20px 0 20px 0;
}

.Projects{
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  padding: 100px 0 50px 0;
  justify-content: center;
}

.ProjectsTextCard{
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: center;
  background-image: url('./Imgs/Rectangle 7.svg'); /* Path to the SVG */
  background-repeat: repeat-x; /* Prevent repeating */
  background-size: auto min(45%, 20vw);
  background-position: center;
  padding: 0;
  gap: 0px;
}

.ProjectsFirst{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.Projects .ProjectsFirst h2{
  font-size: min(4vw, 60px);
}

.Projects .ProjectsFirst a svg{
  width: min(75px,4vw);
  height: auto;
}


.Projects .BusinessCard{
  width: min(750px, 40vw);
}

.Projects .PostersAnim{
  align-self: center;
  width: min(600px, 30vw);
}

.YellowText{
  color: yellow;
}

.spacer{
  height: min(150px, 8vw);
}

.Posters{
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(5, auto); /* 3 columns */
  column-gap: min(2vw, 40px);
  margin: 0 5vw 5vw 5vw;
  align-self: center;
}

.Projects .Posters img{
  width: min(300px, 15vw);
}

.separation h2{
  font-family: Impact;
  font-weight: 400;
  font-size: 100px;
  text-align: right;
  margin: 20px 100px 20px 0;
}

#ProjectsSep h2{
  font-family: Impact;
  font-weight: 400;
  font-size: 100px;
  text-align: left;
  margin: 20px 0 20px 100px;
}

.separation .separator{
  border-top: 5px solid black; 
  margin: 0px 0;
}

.CaraTeriLink{
  display: flex;
  flex-direction: row;
}


.HeroTitle h2 {
  display: flex;
  flex-wrap: wrap;
}

.title-letter {
  display: inline-block;
  color: black; /* Original color */
  transition: color 1s ease; /* Slow transition for hover-out */
}

/* Hover effect on individual letters */
.title-letter:hover {
  color: #0BB1F9; /* Highlight color on hover */
  transition: color 0.15s ease; /* Faster transition for hover-in */
}


.Contact h3{
  font-size: min(2vw, 40px);
    text-align: left;
    margin: 0;
}

Footer{
  background-color: black;
  padding: 20px 0 20px 0;
  color: yellow;
}

Footer h3{
  text-align: center;
}

Footer button{
  background: none;
  padding: 0;
  width: 75px;
}

#YellowSeparator{
  border-top: 3px solid yellow; 
  margin: 20px 0 20px 0;

}

.Socials{
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  gap: 20px;
}

.SvgSocials button{
  width: 75px;
  padding: 0;
}

/* For the button hover effect */
.Socials button:hover {
  background: none; /* No background on hover */
  transform: scale(1.1); /* Slightly scale the button */
}

/* Change fill color of the path and stroke color of the circle on hover */
.Socials button:hover .icon-path {
  background: none;
  fill: #61dafb; /* Change fill color of the path */
}

form button{
  width: 100%;
}

button {
  background: none;
  color: #000; /* Black text on button */
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 25px;
  cursor: pointer;
  width: 10%;
  transition: background-color 0.3s;
}

.CanvasButtons button {
  background: #ffcc00;
  color: #000; /* Black text on button */
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.CanvasButtons button:hover {
  background-color: #e6b800; /* Slightly darker yellow on hover */
}



.Contact{
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: min(5vw, 80px);
  gap: min(80px,2vw);
}

.ContactTitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact h2{
  font-size: min(3.5vw, 60px);
}

.ContactContainer{
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: min(4vw,100px);
}

.FirstContact{
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

/* For the button hover effect */
.FirstContact button:hover {
  background: none; /* No background on hover */
  transform: scale(1.1); /* Slightly scale the button */
}

/* Change fill color of the path and stroke color of the circle on hover */
.FirstContact button:hover .icon-path {
  background: none;
  fill: #61dafb; /* Change fill color of the path */
}
.FirstContact button:hover .icon-circle {
  fill: #000000; /* Change fill color of the path */
}

.SvgSocials{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


/* Mobile (up to 480px) */
@media (max-width: 480px) {
  /* Styles for small mobile screens */

  .App-header {
    font-size: 4vw;
    color: black;
    gap: 3px;
  }

  .App-header img{
    display: none;
  }

  .App-header .HeaderFiller{
    display: none;
  }
  
  .App-header a {
    color: rgb(0, 0, 0); /* Default color */
    text-decoration: none; /* Remove underline */
  }
  .Hero{
    flex-direction: column-reverse;
    margin: 0;
    align-items: normal;
  }

  .HeroContainer{
    max-width: 100vw;
  }

  .HeroTitle{
    position: absolute;
    transform: translateY(-33vw);
    max-width: 100vw;
    left: 15%;
  }


  .Hero .HeroImageContainer{
    margin: min(13vw, 40px) 0 0 0;
  }

  .Hero .HeroImageContainer img{
    height: auto;
    width: 100%;
    z-index: -1;
    padding: 0;
  }

  .Hero h2{
    font-size: 16vw;
    line-height: min(16.5vw, 1em);
  }

  .Hero h3{
    font-size: 8vw;
    margin: 0 0 0 5vw;
  }

  .Hero .spacer{
    height: min(3rem, 10vw);
  }

  #aka{
    line-height: 9vw;
    font-size: 6vw;
    transform: translateX(5vw);
  }

  .Hero .separator{
    margin: 5vw;
  }

  .Hero .FeaturedSkills{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Hero .FeaturedSkillCards{
    gap: 20px;
    grid-template-columns: repeat(2, auto);
    padding: 5%;
  }

  .Hero button{
    position: absolute;
    padding: 0;
  }
  
  /* For the button hover effect */
  .Hero button:hover {
    background: none; /* No background on hover */
  }



  .Hero .FeaturedSkillCards .card {
    width: auto;
    height: auto;
    padding: 15% 15% 15% 15%;
    gap: 0.5em;
  }
  
  .Hero .FeaturedSkillCards .card img {
    width: 66%;
    height: auto;
  }
  
  .Hero .FeaturedSkillCards .card h4 {
    font-size: 4vw;
  }

  #MadeWith{
    text-align: center;
    margin: 0;
  }

  .Hero a{
    padding: 20px;
  }

  .Hero .title-letter{
    color: yellow;
  }

  .Hero a{
    font-size: min(25px, 6vw);
  }

  .separation h2{
    font-size: 15vw;
    margin-left: 5vw;
    text-align: left;
  }

  .Hero .separator{
    margin: 20px;
  }

  #ProjectsSep h2{
    font-size: 15vw;
    margin-left: 5vw;
  }

  .CanvasButtons{
    display: none;
  }

  .AboutPicText {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: center;
    margin: 50px 0 50px 0;
    gap: 20px;
}

  .About{
    margin: 20px 0 0 0 ;
    overflow: hidden;
  }

  #Who1 {
    font-size: min(15vw,60px);
    font-family: Montserrat;
  }

  #Who2{
    font-size: min(7vw, 25px);
    text-align: center;
  }

  .AboutFirst{
    gap: 0;
    align-items: center;
  }

  .AboutText{
    width: auto;
    max-width: 90vw;
    padding: 0;
  }

  .About p{
    font-size: 22px;
  }

  .EyeTrackingImage{
    width: 150vw;
  }

  .Best{
    max-width: 90vw;
    margin-bottom: 80px;
  }

  #RotatingTitle{
    font-size: 40px;
  }

  .SkillCards {
    grid-template-columns: repeat(2, auto);
    gap: 20px;
    margin: 20px 0 20px 0;
  }

  .About .card {
    width: auto;
    height: auto;
    padding: 15% 15% 15% 15%;
    gap: 0.5em;
  }

  .About .card img {
    width: 66%;
    height: auto;
  }

  .About .card h4 {
    font-size: 4vw;
  }

  h3{
    font-size: 8vw;
    max-width: 100vw;
  }

  .Projects{
    margin: 40px 0 40px 0;
    padding: 0;
  }

  .ProjectsTextCard{
    flex-direction: column;
    align-items: initial; 
    background-size: initial;
    background-size: auto 80vw;
    padding: 5vw;
    gap: 0px;
  }

  .Projects .BusinessCard{
    width: auto;
  }

  .Projects .PostersAnim{
    width: min(400px, 85vw);
  }

  .Projects .ProjectsFirst .CaraTeriLink a svg{
    width: 15vw;
    height: auto;
  }

  .Projects .CaraTeriLink a h2{
    font-size: 15vw;
  }

  .Projects .spacer{
    height: 20vw;
  }

  .Projects .Posters{
    margin: 4vw;
    grid-template-columns: repeat(2, auto);
    column-gap: 2vw;
    row-gap: 2vw;
  }

  .Projects .Posters img{
    width: 45vw;
  }

  .Contact{
    padding: 20px;
    margin: 40px 0 40px 0;
    gap: 4em;
  }

  .ContactTitle{
    gap: 1em;
  }

  .Contact h2{
    font-size: 10vw;
    text-align: center;
  }

  .Contact h3{
    text-align: center;
    font-size: 8vw;
    max-width: 100vw;
  }

  .Contact .ContactContainer{
    flex-direction: column;
    gap: 4em;
  }

  .Contact .separator{
    width: 80vw;
  }

  .Contact .SvgSocials{
    gap: 10px;
  }

  .Contact .SvgSocials button{
    width: 15vw;
    height: auto;
  }

  .Contact .SvgSocials h3{
    font-size: 8vw;
    line-break: anywhere;
  }

  .Contact .contact-form-container form input{
    font-size: 6vw;
    width: auto;
  }
  
  .Contact .contact-form-container{
    padding: 0;
  }

  .Contact textarea{
    font-size: 7vw;
    width: auto;
  }

  .Footer button{
    width: 15vw;
  }

  .Footer a {
    font-size: 4vw;
    padding: 10px;
  }
  
  .ButtonsFooter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Footer p{
    font-size: 4vw;
  }
  


}

/* Tablet portrait (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  /* Styles for tablet in portrait mode */


  .App-header{
    font-size: min(4vw, 25px);  
  }

  .Hero{
    flex-direction: column-reverse;
    align-items: normal;
    margin: 40px 0 40px 0;
    gap: 0;
    }

  .Hero .HeroContainer{
    margin: 5% 5% 0px 5%;
  }

  .Hero h2{
    font-size: 19vw;
    line-height: min(19vw, 1em);
  }

  .Hero h3{
    font-size: 6vw;
  }

 
  .Hero .HeroImageContainer img{
    width: 100vw;
  }

  .Hero .FeaturedSkills{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .Hero .FeaturedSkillCards{
    gap: 3vw;
    margin: 40px 0 40px 0;
  }

  .Hero .FeaturedSkillCards .card{
    padding: 3vw 3vw 4vw 3vw;
    width: auto;
    height: auto;
    gap: 1em;
  }

  .Hero .FeaturedSkillCards .card:hover{
    transform: scale(1);
  }

  .Hero .FeaturedSkillCards .card img{
    height: 17vw;
  }

  .Hero .FeaturedSkillCards .card h4{
    font-size: 3.3vw;
  }

  #aka{
    font-size: min(4.5vw,30px);
  }

  .Hero a{
    font-size: 5vw;
  }

  .separation h2{
    font-size: min(15vw, 100px);
    margin: 1vw 5vw 1vw 0;
  }

  #ProjectsSep h2{
    font-size: min(15vw, 100px);
    margin: 1vw 0 1vw 5vw;
  }

  .AboutPicText {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: center;
    margin: 50px 0 50px 0;
    gap: 20px;
}

  .About{
    align-items: normal; 
  }

  #Who1 {
    font-size: 15vw;
    font-family: Montserrat;
  }

  #Who2{
    text-align: center;
    font-size: 5vw;
  }

  .AboutText{
    max-width: 90vw;
    padding: 0;
    width: auto;
  }

  .About p{
    font-size: 22px;
  }

  .Best{
    max-width: 90vw;
    margin-bottom: 80px;
  }

  .EyeTrackingImage{
    width: 750px;
  }

  #RotatingTitle{
    font-size: 55px;
  }

  .SkillCards {
    grid-template-columns: repeat(3, auto);
    gap: 20px;
    margin: 20px 0 20px 0;
  }

  .About .card {
    width: auto;
    height: auto;
    padding: 15% 15% 15% 15%;
    gap: 0.5em;
  }

  .About .card img {
    width: 66%;
    height: auto;
  }

  .About .card h4 {
    font-size: 3vw;
  }

  #ProjectsSep h2{
    margin-left: 5%;
  }

  .Projects{
    margin: 40px 0 40px 0;
    padding: 0;
  }

  .ProjectsTextCard{
    flex-direction: column;
    align-items: initial; 
    background-size: initial;
    background-size: auto 80vw;
    padding: 5vw;
    gap: 0px;
  }

  .Projects .ProjectsFirst .YellowText{
    font-size: 6vw;
  }

  .Projects .BusinessCard{
    width: 80vw;
    align-self: center;  
  }

  .Projects .PostersAnim{
    width: min(550px, 70vw);
  }

  .Projects .ProjectsFirst .CaraTeriLink a svg{
    width: 10vw;
    height: auto;
  }

  .Projects .CaraTeriLink a h2{
    font-size: 10vw;
  }

  .Projects .ProjectsFirst h3{
    font-size: 5vw;
  }

  .Projects .spacer{
    height: 20vw;
  }

  .Projects .Posters{
    margin: 4vw;
    grid-template-columns: repeat(2, auto);
    column-gap: 2vw;
    row-gap: 2vw;
  }

  .Projects .Posters img{
    width: 45vw;
  }

  .Contact{
    padding: 0;
    margin: 5%;
  }

  .ContactTitle{
    gap: 40px;
  }

  .Contact h2{
    font-size: 10vw;
    text-align: center;
  }

  .Contact .ContactTitle h3{
    text-align: center;
  }

  .Contact .ContactContainer{
    flex-direction: column;
    gap: 80px;
  }

  .Contact .separator{
    width: 80vw;
  }

  .Contact .SvgSocials{
    gap: 10px;
  }

  .Contact .SvgSocials button{
    width: 15vw;
    height: auto;
  }

  .Contact .SvgSocials h3{
    font-size: 5vw;
    line-break: anywhere;
  }

  .Contact .contact-form-container form input{
    font-size: 6vw;
    width: auto;
  }
  
  .Contact .ContactTitle h3{
    font-size: 6vw;
  }
  
  .Contact .contact-form-container{
    padding: 0;
  }

  .Contact .contact-form-container h3{
    font-size: 6vw;
  }

  .Contact textarea{
    font-size: 7vw;
    width: auto;
  }

  .Footer a{
    font-size: min(4vw, 25px);
  }

}

/* Tablet landscape and small desktops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1250px) {
  /* Styles for tablet in landscape mode and small desktops */


  .App-header .HeaderFiller{
    display: none;
  }

  .Hero .FeaturedSkillCards .card {
    width: min(150px, 10vw);
  }

  .Hero .FeaturedSkillCards .card img{
    height: min(5vw, 100px);
  }

  .Hero .FeaturedSkillCards .card h4{
    font-size: min(1.5vw, 20px);
  }

  .separation h2{
    font-size: min(9vw, 100px);
    margin: 1vw 5vw 1vw 0;
  }

  #ProjectsSep h2{
    font-size: min(9vw, 100px);
    margin: 1vw 0 1vw 5vw;
  }

  .AboutPicText{
    flex-direction: column;
  }

  .AboutFirst{
    gap: 0;
  }

  .About .AboutPicText .VerticalSeparator{
    display: none;
  }

  .AboutText{
    width: min(75vw, 750px);
  }

  .EyeTrackingImage{
    width: min(75vw, 750px);;
  }

  #RotatingTitle{
    padding-top: 40px;
  }

  .SkillCards{
    grid-template-columns: repeat(4, auto);
  }

  .About .SkillCards .card {
    width: min(150px, 16vw);
  }

  .About .SkillCards .card img{
    height: min(10vw, 100px);
  }

  .About .SkillCards .card h4{
    font-size: min(2vw, 20px);
  }

  .Contact .ContactContainer{
    flex-direction: column;
  }

  .Contact .VerticalSeparator{
    display: none;
  }

  .Contact input, .Contact textarea {
    font-size: min(4vw,40px);
    width: min(80vw,850px);
  }

  .Contact .ContactContainer .FirstContact{
    gap: min(1vw,20px);
  }

  .Contact h2{
    text-align: center;
    font-size: min(60px, 5vw);
  }

  .Contact h3{
    font-size: min(40px, 4vw);
  }

}
